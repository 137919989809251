<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <b-card>
      <div class="w-100">
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="text">Address</h3>
        </div>
        <div class="ml-5">
          <h4 class="ml-4">Pick Up Date: {{ form.pickup_datetime }}</h4>
        </div>
        <b-row class="d-flex align-items-center justify-content-center mx-1">
          <b-col cols="5" class="border border-primary mr-5 py-3">
            <h4>{{ form.sender_first_name }} {{ form.sender_last_name }}</h4>
            <h6>
              {{ form.sender_address_line_1 }} {{ form.sender_address_line_2 }}
              {{ form.sender_postcode }} {{ form.sender_area }}
              {{ form.sender_state }} {{ form.sender_country }}
            </h6>
            <h6>Phone Number: {{ form.sender_phone_number }}</h6>
          </b-col>
          <b-col cols="5" class="border border-primary ml-5 py-3">
            <h4>
              {{ form.recipient_first_name }} {{ form.recipient_last_name }}
            </h4>
            <h6>
              {{ form.recipient_address_line_1 }}
              {{ form.recipient_address_line_2 }} {{ form.recipient_postcode }}
              {{ form.recipient_area }} {{ form.recipient_state }}
              {{ form.recipient_country }}
            </h6>
            <h6>Phone Number: {{ form.recipient_phone_number }}</h6>
          </b-col>
        </b-row>
      </div>
      <div class="w-100 my-5">
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="BoxIcon" size="35" color="white" />
          </div>
          <h3 class="text">Product Details</h3>
        </div>
        <b-card class="tableBG ml-5">
          <div class="p-2 pb-5 mx-">
            <vue-good-table
              :columns="incoming.columns"
              :rows="incoming.rows"
              styleClass="vgt-table"
            >
            </vue-good-table>
          </div>
        </b-card>
      </div>
      <div>
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="DollarSignIcon" size="35" color="white" />
          </div>
          <h3 class="text">Total</h3>
        </div>
        <div class="d-flex align-items-center justify-content-between ml-5">
          <div class="totalText ml-4">
            <div>
              <h4>Voucher Code</h4>
            </div>
            <div>
              <h4>Unit Price</h4>
            </div>
            <div>
              <h4>Sub Total</h4>
            </div>
            <div>
              <h4>Pick Up Charge</h4>
            </div>
            <div>
              <h4>Tax Charge</h4>
            </div>
            <div>
              <h4>Grand Total</h4>
            </div>
          </div>
          <div class="w-50">
            <div>
              <input
                type="text"
                class="form-control w-25 ml-5"
                placeholder="Code"
                aria-label="Code"
              />
            </div>
            <div>
              <h4 class="ml-5">RM 10.00</h4>
            </div>
            <div>
              <h4 class="ml-5">RM 10.00</h4>
            </div>
            <div>
              <h4 class="ml-5">RM 10.00</h4>
            </div>
            <div>
              <h4 class="ml-5">0.00</h4>
            </div>
            <div>
              <h4 class="ml-5">RM 110.80</h4>
            </div>
          </div>
          <div class="w-50 text-right">
            <h4 class="mr-5 pr-3">WE ACCEPT</h4>
            <img
              :src="require('@/assets/images/logo/visa.png')"
              class="img-fluid w-25 mr-5 pr-2"
            />
          </div>
        </div>
      </div>
    </b-card>
    <div class="d-flex justify-content-center align-items-center mt-5">
      <b-button
        @click="onBack"
        variant="light"
        v-b-modal.modal-center
        class="box-shadow-1 px-5 mr-3 w-25"
        >Back</b-button
      >
      <b-button
        @click="onSubmit"
        variant="gradient-primary"
        v-b-modal.modal-center
        class="box-shadow-1 px-5 w-25"
        >Proceed Payment</b-button
      >
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
  },

  data() {
    return {
      rangeDate: null,
      underCoverage: "empty",
      unitPrice: "",
      subTotal: "",
      form: {},
      incoming: {
        columns: [
          {
            label: "Weight ( kg )",
            field: "item_weight",
            tdClass: "text-center",
          },
          {
            label: "Quantity",
            field: "quantity",
            tdClass: "text-center",
          },
          {
            label: "Remarks",
            field: "remarks",
            tdClass: "text-center",
          },
        ],
        rows: [{}],
      },
    };
  },
  created() {
    this.form = this.$route.params.data;
    this.incoming.rows[0].quantity = this.form.quantity;
    this.incoming.rows[0].remarks = this.form.remarks;
    this.incoming.rows[0].item_weight = this.form.item_weight;
    this.$http
      .get(
        `quote?postcode_origin=${this.form.sender_postcode}&postcode_destination=${this.form.recipient_postcode}`
      )
      .then((response) => {
        if (response.data.status) {
          this.unitPrice = response.data.quotation.unit_price;
          this.subTotal = this.unitPrice * this.form.quantity;
        }
      });
  },

  methods: {
    onSubmit() {
      this.$router.push({
        name: "dhl-order-paymentmethod",
        params: { data: this.form },
      });
    },
    onBack() {
      this.$router.push({
        name: "dhl-order-finalize",
        params: { data: this.form },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
.tableBG {
  background-color: #f7f8fc;
}
.totalText {
  margin-top: 1vh;
  width: 9vw;
}
</style>
